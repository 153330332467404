<template>
  <div>
    <ul class="opcate">
      <!-- <li>
        <span :class="tableIndex==1?'selected':''" @click="tableIndex=1">开奖结果</span>
      </li> -->
      <!-- <li><em>|</em><span :class="tableIndex==2?'selected':''" @click="tableIndex=2">牛牛</span></li> -->

    </ul>
    <!-- 表格 -->
    <div class="res-open-content type-pks yzpks">
      <div class="r-o-pks">
        <table class="com-table">
          <tr>
            <th rowspan="">期数</th>
            <th rowspan="">时间</th>
            <th colspan="10">开出号码</th>
            <th colspan="3">冠亚军和</th>
            <th colspan="5">1~5 龙虎</th>
          </tr>
          <tr v-for="item in list" :key="item.Expect">
            <td>{{item.Expect}}</td>
            <td>{{item.Opentime|substrSome}}</td>
            <td v-for="code in item.codeArr" :key="code" class="balls_pk td-ball">
              <p :class="'b'+code"></p>
            </td>

            <td :class="fontColor[ele]" v-for="ele in item.lutuResult" :key="ele">{{ele}}</td>
          </tr>
        </table>
        <div class="page">
          <!-- :page-sizes="[20,50,100,200]" -->
          <el-pagination @current-change="handleCurrentChange" :current-page="pages.pagenum" :page-size="pages.pagecount" layout="total, prev, pager, next, jumper" :total="totals">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import kjmixins from "../mixins/kjmixins";
export default {
  name: "kjpk10",
  props: [""],
  mixins: [kjmixins],
  data() {
    return {};
  },

  components: {},
  created() {},
  computed: {},

  methods: {},
};
</script>
<style  scoped>
.r-o-pks .com-table tr td.nnwin {
  background: #f7f178;
}
.r-o-pks .com-table tr td.ds_d,
.r-o-pks .com-table tr td.dx_d,
.r-o-pks .com-table tr td.lh_l {
  color: red;
}
.r-o-pks .com-table tr td.ds_s,
.r-o-pks .com-table tr td.dx_x,
.r-o-pks .com-table tr td.lh_h {
  color: #00f;
}
</style>